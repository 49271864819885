import { config } from './Constants'
import { Dimmer, Header, Icon } from 'semantic-ui-react'
import { amedizonApi } from './lib/AmedizonApi'
import { HashRouter,Switch } from 'react-router-dom';
import { ReactKeycloakProvider } from '@react-keycloak/web'
import { Route} from "react-router-dom";
import Keycloak from 'keycloak-js'
import React, { Component } from "react";
import store from './redux/store';
import { useKeycloak } from '@react-keycloak/web'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

const TheLayout = React.lazy(() => import('./containers/TheLayout'));

const keycloak = new Keycloak({
  url: `${config.url.KEYCLOAK_BASE_URL}/auth/`,
  realm: "Backend",
  clientId: "amedizon-data-client"
})

const handleOnEvent = async (event, error) => {
    if (event === 'onAuthSuccess') {
      if (keycloak.authenticated) {
      }
    }
  }

const loadingComponent = (
  <Dimmer inverted active={true} page>
    <Header style={{ color: '#4d4d4d' }} as='h2' icon inverted>
      <Icon loading name='cog' />
      <Header.Content>Keycloak is loading
        <Header.Subheader style={{ color: '#4d4d4d' }}>or running authorization code flow with PKCE</Header.Subheader>
      </Header.Content>
    </Header>
  </Dimmer>
)

const initOptions = { onLoad: 'check-sso', pkceMethod: 'S256' }

class App extends Component {

  render() {
    return (
      <ReactKeycloakProvider
        authClient={keycloak}
        initOptions={initOptions}
        LoadingComponent={loadingComponent}
        onEvent={(event, error) => handleOnEvent(event, error)}
      >
        <HashRouter>
            <React.Suspense fallback={loading}>
              <Switch>
                <Route path="/" render={props => <TheLayout {...props}/>} />
              </Switch>
            </React.Suspense>
        </HashRouter>
      </ReactKeycloakProvider>
    );
  }
}

export default App;