const prod = {
  url: {
    KEYCLOAK_BASE_URL: "https://keycloak.isinol.de",
    API_BASE_URL: "https://api.isinol.de",
  }
}

const dev = {
  url: {
    KEYCLOAK_BASE_URL: "http://localhost:8091",
    API_BASE_URL: 'http://127.0.0.1:8070'
  }
}

export const config = process.env.NODE_ENV === 'development' ? dev : prod

export const XHR_SUCCESS = 'Import gestartet';
export const XHR_FAILED = 'Import fehlgeschlagen';
export const XHR_CANCELED = 'Import abgebrochen';
