import { uploadXHR } from '../utils/xhr';
import { config } from '../Constants';

export const rxUpload = formData => {
  console.log(window.location.toString().split("/").pop())
  var importPath = window.location.toString().split("/").pop()
  return uploadXHR({
    method: 'POST',
    url: config.url.API_BASE_URL + '/upload/xml/' + importPath,
    body: formData,
  });
};

// export const rxUpload = formData => ({
//   url: 'http://localhost:8070/upload',
//   method: 'POST',
//   headers: {
//     'Content-Type': 'application/x-www-form-urlencoded',
//   },
//   body: formData,
// });
