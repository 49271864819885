import axios from 'axios'
import {config} from './../Constants'

const instance = axios.create({
  baseURL: config.url.API_BASE_URL
})

instance.interceptors.response.use(response => {
  return response;
}, function (error) {
  if (error.response.status === 404) {
    return { status: error.response.status };
  }
  return Promise.reject(error.response);
});

export const amedizonApi = {
  getProducts,
  getBaskets,
  getPharmacies,
  getTexts,
  getText,
  savePharmacy,
  saveBasket,
  saveProduct,
  savePharmacyProduct,
  getSimplifiedPharmacies,
  getSimplifiedBaskets,
  getNavbarItems,
  saveNavbarItem,
  getUpperCategories,
  saveCategory,
  getSubCategories,
  deleteProduct,
  deleteDeliveryMethod,
  deleteImage,
  deletePharmacy,
  deletePharmacyProduct,
  deleteCategory,
  getCategory,
  getImports,
  getManufacturers,
  getDosageforms,
  getProduct,
  getPharmacy,
  getBillingRecipient,
  getPharmacyProduct,
  getPharmacyProducts,
  getPharmacyProductsPaginated,
  getProductsSimplified,
  getAdminKeyData,
  computeProductRankings,
  getRankedProducts,
  searchProduct,
  getPharmacyProductById,
  getSearchQueries,
  getProductClicks,
  saveRankedProducts,
  getBasketsForPharmacyAndDate,
  getAccumulatedBasketsForPharmacyAndDate,
  getGebrauchsinfoDashboardData,
  startGebrauchsInfoImport,
  getGebrauchsInfoPdfAdditionalData,
  getGebrauchsInfoPdfLinks,
  getGebrauchsInfoPzns,
  getProductsPaginated,
  deleteRankedProducts,
  getSimplifiedPaymentMethods,
  getSimplifiedDeliveryCountries,
  getStudies,
  deleteStudy,
  getStudy,
  getContactRequestsPaginated,
  getAllContactRequestsPaginated,
  saveStudy,
  saveText,
  deleteContactRequest,
  getImport,
  saveScheduledImport,
  getScheduledImports,
  deleteScheduledImport,
  getPharmaciesWithoutImports,
  getBasketParameterFormats,
  getProductParameterFormats,
  getCsvDelimitersFromApi,
  startCsvAndAwinImports,
  getProductImportJobAlreadRunning,
  getSimplifiedDeliveryServices,
  getInvoiceData,
  getInvoice,
  saveInvoiceData,
  getInvoicesForPharmacy,
  createInvoice,
  getInvoiceFromApi,
  saveInvoice,
  deleteInvoice,
  sendInvoiceMail,
}

function getProducts(token){
  return instance.get(`/api/products`, {
    headers: { 'Authorization': bearerAuth(token) }
  })
}

function getPharmacyProducts(token, pharmacyId){
  return instance.get(`/api/pharmacy_products/` + pharmacyId, {
    headers: { 'Authorization': bearerAuth(token) }
  })
}

function getInvoicesForPharmacy(token, pharmacyId){
  return instance.get(`/api/invoices/pharmacy/` + pharmacyId, {
    headers: { 'Authorization': bearerAuth(token) }
  })
}

function getInvoice(token, id){
  return instance.get(`/api/invoices/` + id, {
    headers: { 'Authorization': bearerAuth(token) }
  })
}

function getPharmacyProductsPaginated(token, pharmacyId, first, last){
  return instance.get("/api/pharmacy_products/paginated/" + pharmacyId + "?first=" + first + "&last=" + last, {
    headers: { 'Authorization': bearerAuth(token) }
  })
}

function getContactRequestsPaginated(token, studyId, first, last){
  return instance.get("/api/requests/contact/paginated/" + studyId + "?first=" + first + "&last=" + last, {
    headers: { 'Authorization': bearerAuth(token) }
  })
}

function getAllContactRequestsPaginated(token, first, last){
  return instance.get("/api/requests/contact/paginated/all" + "?first=" + first + "&last=" + last, {
    headers: { 'Authorization': bearerAuth(token) }
  })
}

function getProductsSimplified(token){
  return instance.get(`/api/products/simplified`, {
    headers: { 'Authorization': bearerAuth(token) }
  })
}

function getProduct(token, id){
  return instance.get(`/api/products/` + id, {
    headers: { 'Authorization': bearerAuth(token) }
  })
}

function getPharmacy(token, id){
  return instance.get(`/api/pharmacies/` + id, {
    headers: { 'Authorization': bearerAuth(token) }
  })  
}

function getImport(token, id){
  return instance.get(`/api/scheduled/imports/` + id, {
    headers: { 'Authorization': bearerAuth(token) }
  })  
}

function saveScheduledImport(scheduledImport, type, token){
  return instance.post('/api/scheduled/imports/type/' + type, scheduledImport, {
      headers: {
        'Content-type': 'application/json',
        'Authorization': bearerAuth(token)
      }
    }) 
}


function getBillingRecipient(token, id){
  return instance.get(`/api/billing/recipient/` + id, {
    headers: { 'Authorization': bearerAuth(token) }
  })  
}

function getStudy(token, id){
  return instance.get(`/api/studies/` + id, {
    headers: { 'Authorization': bearerAuth(token) }
  })
}

function getBaskets(token){
  return instance.get(`/api/baskets`, {
    headers: { 'Authorization': bearerAuth(token) }
  })
}

function getBasketsForPharmacyAndDate(token, pharmacyId, startDate, endDate){
  return instance.get(`/api/baskets/pharmacy/`+ pharmacyId + "?startDate=" + startDate + "&endDate=" + endDate, {
    headers: { 'Authorization': bearerAuth(token) }
  })
}

function createInvoice(token, pharmacyId, startDate, endDate){
  return instance.post(`/api/invoices/pharmacy/`+ pharmacyId + "?startDate=" + startDate + "&endDate=" + endDate, {
    headers: { 'Authorization': bearerAuth(token) }
  })
}

function getAccumulatedBasketsForPharmacyAndDate(token, pharmacyId, startDate, endDate){
  return instance.get(`/api/baskets/accumulated/pharmacy/`+ pharmacyId + "?startDate=" + startDate + "&endDate=" + endDate, {
    headers: { 'Authorization': bearerAuth(token) }
  })
}

function getPharmacies(token){
  return instance.get(`/api/pharmacies`, {
    headers: { 'Authorization': bearerAuth(token) }
  })
}

function getPharmaciesWithoutImports(token){
  return instance.get(`/api/pharmacies/without_imports`, {
    headers: { 'Authorization': bearerAuth(token) }
  })
}

function startCsvAndAwinImports(token){
  return instance.get(`/api/scheduled/imports/start/all`, {
    headers: { 'Authorization': bearerAuth(token) }
  })
}

function getProductImportJobAlreadRunning(token){
  return instance.get(`/api/scheduled/imports/status`, {
    headers: { 'Authorization': bearerAuth(token) }
  })
}

function getBasketParameterFormats(token){
  return instance.get(`/api/pharmacies/basket_parameter_formats`, {
    headers: { 'Authorization': bearerAuth(token) }
  })
}

function getProductParameterFormats(token){
  return instance.get(`/api/pharmacies/product_parameter_formats`, {
    headers: { 'Authorization': bearerAuth(token) }
  })
}

function getCsvDelimitersFromApi(token){
  return instance.get(`/api/scheduled/imports/csv/delimiters`, {
    headers: { 'Authorization': bearerAuth(token) }
  })
}

function getTexts(token){
  return instance.get(`/api/text`, {
    headers: { 'Authorization': bearerAuth(token) }
  })
}

function getStudies(token){
  return instance.get(`/api/studies`, {
    headers: { 'Authorization': bearerAuth(token) }
  })
}

function getSimplifiedPharmacies(token){
  return instance.get(`/api/pharmacies/simplified`, {
    headers: { 'Authorization': bearerAuth(token) }
  })
}

function getSimplifiedPaymentMethods(token){
  return instance.get(`/api/payment_methods/simplified`, {
    headers: { 'Authorization': bearerAuth(token) }
  })
}

function getSimplifiedDeliveryCountries(token){
  return instance.get(`/api/delivery_countries/simplified`, {
    headers: { 'Authorization': bearerAuth(token) }
  })
}

function getSimplifiedBaskets(token){
  return instance.get(`/api/baskets/simplified`, {
    headers: { 'Authorization': bearerAuth(token) }
  })
}

function getNavbarItems(token){
  return instance.get(`/api/categories/navbar`, {
    headers: { 'Authorization': bearerAuth(token) }
  })
}

function getUpperCategories(token){
  return instance.get(`/api/categories/upper`, {
    headers: { 'Authorization': bearerAuth(token) }
  })
}

function getCategory(id, token){
  return instance.get(`/api/categories/` + id, {
    headers: { 'Authorization': bearerAuth(token) }
  })
}
   
function getText(token, text_id){
  return instance.get(`/api/text/` + text_id,{
    headers: { 'Authorization': bearerAuth(token) }
  })
}

function getPharmacyProduct(pharmacyId, productId, token){
  return instance.get(`/api/pharmacy_products/` + pharmacyId + "/" + productId, {
    headers: { 'Authorization': bearerAuth(token) }
  })
}

function getPharmacyProductById(productId, token){
  return instance.get(`/api/pharmacy_products/id/` + productId, {
    headers: { 'Authorization': bearerAuth(token) }
  })
}

function getSearchQueries(token){
  return instance.get(`/api/search/queries/`, {
    headers: { 'Authorization': bearerAuth(token) }
  })
}

function getProductClicks(token){
  return instance.get(`/api/logging/clicks/products`, {
    headers: { 'Authorization': bearerAuth(token) }
  })
}

function getImports(type, token){
  return instance.get(`/api/imports/` + type, {
    headers: { 'Authorization': bearerAuth(token) }
  })
}

function getScheduledImports(token, type){
  return instance.get(`/api/scheduled/imports/type/` + type, {
    headers: { 'Authorization': bearerAuth(token) }
  })
}

function getManufacturers(first, last, token){
  return instance.get(`/api/manufacturers/paginated?first=` + first + "&last=" + last, {
    headers: { 'Authorization': bearerAuth(token) }
  })
}

function getDosageforms(token){
  return instance.get(`/api/dosageforms/`, {
    headers: { 'Authorization': bearerAuth(token) }
  })
}

function getSubCategories(token){
  return instance.get(`/api/categories/sub`, {
    headers: { 'Authorization': bearerAuth(token) }
  })
}

function getRankedProducts(token){
  return instance.get(`/api/ranked/products`, {
    headers: { 'Authorization': bearerAuth(token) }
  })
}

function searchProduct(query, token){
  return instance.get(`/api/search/products/sorted?query=` + query + "&" + "countup=false", {
    headers: { 'Authorization': bearerAuth(token) }
  })
}


function computeProductRankings(type, token) {
  return instance.post('/api/ranked/products/rank/' + type, {
    headers: {
      'Content-type': 'application/json',
      'Authorization': bearerAuth(token)
    }
  })
}


function savePharmacy(pharmacy, token) {
  return instance.post('/api/pharmacies', pharmacy, {
    headers: {
      'Content-type': 'application/json',
      'Authorization': bearerAuth(token)
    }
  })
}

function saveBasket(basket, token) {
  return instance.post('/api/baskets', basket, {
    headers: {
      'Content-type': 'application/json',
      'Authorization': bearerAuth(token)
    }
  })
}

function saveInvoiceData(data, token) {
  return instance.post('/api/invoice_data', data, {
    headers: {
      'Content-type': 'application/json',
      'Authorization': bearerAuth(token)
    }
  })
}

function saveCategory(category, token) {
  return instance.post('/api/categories', category, {
    headers: {
      'Content-type': 'application/json',
      'Authorization': bearerAuth(token)
    }
  })
}

function saveStudy(study, token) {
  return instance.post('/api/studies', study, {
    headers: {
      'Content-type': 'application/json',
      'Authorization': bearerAuth(token)
    }
  })
}

function saveInvoice(invoice, token) {
  return instance.post('/api/invoices', invoice, {
    headers: {
      'Content-type': 'application/json',
      'Authorization': bearerAuth(token)
    }
  })
}

function sendInvoiceMail(token, id) {
  return instance.post('/api/email/invoices/' + id, {
    headers: {
      'Authorization': bearerAuth(token)
    }
  })
}

function saveText(clientText, token) {
  return instance.post('/api/text', clientText, {
    headers: {
      'Content-type': 'application/json',
      'Authorization': bearerAuth(token)
    }
  })
}

function saveProduct(product, token) {
  return instance.post('/api/products', product, {
    headers: {
      'Content-type': 'application/json',
      'Authorization': bearerAuth(token)
    }
  })
}

function savePharmacyProduct(product, token) {
  return instance.post('/api/pharmacy_products/', product, {
    headers: {
      'Content-type': 'application/json',
      'Authorization': bearerAuth(token)
    }
  })
}

function saveRankedProducts(data, token) {
  return instance.post('/api/ranked/products/multiple', data, {
    headers: {
      'Content-type': 'application/json',
      'Authorization': bearerAuth(token)
    }
  })
}

function deleteRankedProducts(token) {
  return instance.post('/api/ranked/products/delete', {
    headers: {
      'Content-type': 'application/json',
      'Authorization': bearerAuth(token)
    }
  })
}

function saveNavbarItem(item, token) {
  return instance.post('/api/categories/navbar', item, {
    headers: {
      'Content-type': 'application/json',
      'Authorization': bearerAuth(token)
    }
  })
}


function deleteProduct(product, token) {
  return instance.post('/api/products/'+product.id, {
    headers: {
      'Content-type': 'application/json',
      'Authorization': bearerAuth(token)
    }
  })
}

function deleteDeliveryMethod(method, token) {
  return instance.post('/api/delivery_methods/'+method.id, {
    headers: {
      'Content-type': 'application/json',
      'Authorization': bearerAuth(token)
    }
  })
}

function deleteScheduledImport(id, token) {
  return instance.post('/api/scheduled/imports/'+id, {
    headers: {
      'Content-type': 'application/json',
      'Authorization': bearerAuth(token)
    }
  })
}

function deleteInvoice(token, id) {
  return instance.post('/api/invoices/'+id, {
    headers: {
      'Content-type': 'application/json',
      'Authorization': bearerAuth(token)
    }
  })
}

function deleteImage(image, token) {
  return instance.post('/api/images/'+ image.id, {
    headers: {
      'Content-type': 'application/json',
      'Authorization': bearerAuth(token)
    }
  })
}

function deleteContactRequest(id, token) {
  return instance.post('/api/requests/contact/'+ id, {
    headers: {
      'Content-type': 'application/json',
      'Authorization': bearerAuth(token)
    }
  })
}

function deletePharmacy(id, token) {
  return instance.post('/api/pharmacies/'+ id, {
    headers: {
      'Content-type': 'application/json',
      'Authorization': bearerAuth(token)
    }
  })
}

function deleteStudy(id, token) {
  return instance.post('/api/studies/'+ id, {
    headers: {
      'Content-type': 'application/json',
      'Authorization': bearerAuth(token)
    }
  })
}

function deletePharmacyProduct(productId, pharmacyId, token) {
  return instance.post('/api/pharmacy_products/'+ pharmacyId + "/" + productId, {
    headers: {
      'Content-type': 'application/json',
      'Authorization': bearerAuth(token)
    }
  })
}

function deleteCategory(category, token) {
  return instance.post('/api/categories/'+category.id, {
    headers: {
      'Content-type': 'application/json',
      'Authorization': bearerAuth(token)
    }
  })
}

function getAdminKeyData(token) {
  return instance.get('/api/admin/keydata', {
    headers: {
      'Content-type': 'application/json',
      'Authorization': bearerAuth(token)
    }
  })
}

function getGebrauchsinfoDashboardData(token) {
  return instance.get('/api/gebrauchsinfo/dashboard', {
    headers: {
      'Content-type': 'application/json',
      'Authorization': bearerAuth(token)
    }
  })
}

function startGebrauchsInfoImport(token) {
  return instance.post('/api/gebrauchsinfo/imports/trigger', {
    headers: {
      'Content-type': 'application/json',
      'Authorization': bearerAuth(token)
    }
  })
}

function getGebrauchsInfoPdfAdditionalData(first, last, token) {
  return instance.get('/api/gebrauchsinfo/paginated/data?first='+first+"&last="+last, {
    headers: {
      'Content-type': 'application/json',
      'Authorization': bearerAuth(token)
    }
  })
}

function getGebrauchsInfoPdfLinks(first, last, token) {
  return instance.get('/api/gebrauchsinfo/paginated/pdflink?first='+first+"&last="+last, {
    headers: {                                                      
      'Content-type': 'application/json',
      'Authorization': bearerAuth(token)
    }
  })
}

function getGebrauchsInfoPzns(first, last, token) {
  return instance.get('/api/gebrauchsinfo/paginated/pzn?first='+first+"&last="+last, {
    headers: {
      'Content-type': 'application/json',
      'Authorization': bearerAuth(token)
    }

  })
}

function getProductsPaginated(first, last, token) {
  return instance.get('/api/products/paginated?first='+first+"&last="+last, {
    headers: {
      'Content-type': 'application/json',
      'Authorization': bearerAuth(token)
    }
  })
}

function getSimplifiedDeliveryServices(token) {
  return instance.get('/api/delivery_services/simplified', {
    headers: {
      'Content-type': 'application/json',
      'Authorization': bearerAuth(token)
    }
  })
}

function getInvoiceData(token) {
  return instance.get('/api/invoice_data', {
    headers: {
      'Content-type': 'application/json',
      'Authorization': bearerAuth(token)
    }
  })
}

function getInvoiceFromApi(token, id) {
  return instance.get('/api/invoice/' + id, {
    headers: {
      'Content-type': 'application/json',
      'Authorization': bearerAuth(token)
    }
  })
}

function bearerAuth(token) {
  return `Bearer ${token}`
}